
html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif !important;
  background-color: black;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  overflow: hidden; }

body {
  margin: 0;
  padding: 0;
  background-color: black;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-family: 'Roboto', sans-serif !important;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app-click-off-pane {
  height: 100%;
  width: 100vw;
  position: fixed;
  top: 80px;
  left: 0px;
  z-index: 25; }

.app-container {
  height: 100vh;
  width: 100vw;
  background-color: #FFFFFF;
  z-index: 2;

  .app-header {
    position: fixed;
    top: 0;
    left: 0;
    height: 60px;
    width: 100vw;
    z-index: 200; }

  .side-menu {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 300px;
    overflow-y: scroll;
    z-index: 300;
    transform: translateX(-100%);
    transition: all 0.6s ease-in-out; }

  .side-menu-active {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 300px;
    height: 100%;
    overflow-y: scroll;
    z-index: 300;
    transform: translateX(0);
    transition: all 0.4s ease-in-out; }

  .app-main {
    position: fixed;
    top: 80px;
    left: 0px;
    height: calc(100% - 60px);
    height: -webkit-calc(100% - 60px);
    width: 100%;
    z-index: 1;
    overflow: scroll; } }


/* works for most mobile browsers but not firefox */
::-webkit-scrollbar {
  display: none; }
